/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image, Text, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.zemnevykopoveprace.sk"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-9w63j1 css-42e4bw js-anim  --anim2 --anim-s5 --full pb--40" name={"uvod"} fullscreen={true} anim={"2"} animS={"5"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/30902f2c9ea14d399601318143afd6e5_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --shape3 js-anim  --anim2 --shadow2 --center el--1 --full flex--bottom" columns={"1"} fullscreen={true} anim={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--shadow2 ff--3 fs--48 lh--12 mt--02" content={"<span style=\"color: rgb(250, 251, 253); font-weight: bold; font-style: italic;\">Spoločnosť, na ktorú sa môžete spoľahnúť, lebo nám záleží na Vašej spokojnosti.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: var(--white);\">Sme spoľahlivá firma s osobným prístupom, bohatými skúsenosťami v oblasti stavebníctva a s vlastným vozovým parkom.\n<br>Na stavebnom trhu pôsobíme od roku 2017 a vďaka naším dlhoročným skúsenostiam poskytujeme kvalitné služby v oblasti zemných a výkopových prác, búracích, či terénnych úprav a mnoho iných služieb, ktoré si môžete prezrieť v našom portfóliu.&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --anim-s2 bg--center pb--20 pt--60" name={"informacie"} lightbox={false} anim={"2"} animS={"2"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26" style={{"maxWidth":995}} content={"<span style=\"font-style: italic;\">Vitajte na našej stránke a dovoľte nám predstaviť sa...<br>Sme rýchlo sa rozvíjajúca firma, pohybujúca sa v oblasti zemných a výkopových prác a terénnych úprav našimi bagrami a inými stavebnými strojmi, pôsobíme na strednom Slovensku. <br>&nbsp;Naším cieľom je spokojnosť zákazníka a preto sa maximálne orientujeme na jeho požiadavky, od analýzy jeho potrieb až po realizáciu komplexných riešení. <br>Naše služby a techniku plánujeme neustále rozširovať, podľa potrieb našich zákazníkov, aby boli vždy spokojní s dobre odvedenou prácou ktorá je pre nás samozrejmosťou. Našou výhodou je, že našimi otočnými bagrami MECALAC dokážeme na stavenisku vykonávať všetky úkony od začiatku stavby až po finalizáciu. Doteraz sme u našich zákazníkov realizovali prípravy stavenísk, vyčistenie a zrovnanie povrchov, výkopy základov, výkopy prípojok pre inžinierske siete, finálne úpravy terénu a mnoho iného...<br>&nbsp;Pozrite si našu ponuku a v prípade záujmu nás neváhajte kontaktovať.&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/623993c1e7af4ca29abce60f0cf7dc71_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/623993c1e7af4ca29abce60f0cf7dc71_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/623993c1e7af4ca29abce60f0cf7dc71_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/623993c1e7af4ca29abce60f0cf7dc71_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8469/623993c1e7af4ca29abce60f0cf7dc71_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/8469/623993c1e7af4ca29abce60f0cf7dc71_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--60" name={"nase_sluzby"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3 pb--20 pt--20" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/cc3bcefc5cad4b02af50f474b4295b9c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/cc3bcefc5cad4b02af50f474b4295b9c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/cc3bcefc5cad4b02af50f474b4295b9c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/cc3bcefc5cad4b02af50f474b4295b9c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8469/cc3bcefc5cad4b02af50f474b4295b9c_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Zemné úpravy"}>
              </Subtitle>

              <Text className="text-box" content={"- svahovanie, úpravy pozemkov (okolo domov, budov)\n- planírovanie terénu \n\n- čistenie pozemkov, vytrhávanie koreňov\n- prípravy podkladu pod chodníky, dlažby \n- čistenie žľabov, vodných tokov \n- prevoz a rozhŕňanie zeminy a štrkovín\n- príprava a vyčistenie objektu na následné budovanie\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/b51c4b5d8cf74da9b6185b209f3a01fc_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/b51c4b5d8cf74da9b6185b209f3a01fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/b51c4b5d8cf74da9b6185b209f3a01fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/b51c4b5d8cf74da9b6185b209f3a01fc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8469/b51c4b5d8cf74da9b6185b209f3a01fc_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Výkopové práce"}>
              </Subtitle>

              <Text className="text-box" content={"- výkop základov pod budovy, domy,...\n<br>- výkop pre inžinierske siete (voda, plyn, elektrika, kanalizácie) <br>&nbsp;- výkop akýchkoľvek jám (bazény, pivnice, ČOV, žumpy, vodomerné šachty) <br>&nbsp;- odkopávanie základov&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/b509f23e5be5418291e0066f756f4146_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/b509f23e5be5418291e0066f756f4146_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/b509f23e5be5418291e0066f756f4146_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/b509f23e5be5418291e0066f756f4146_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8469/b509f23e5be5418291e0066f756f4146_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"Ďaľšie služby"}>
              </Subtitle>

              <Text className="text-box" content={"- búranie a demolácia menších objektov, budov <br>&nbsp;- vyčistenie a odvoz sute zo staveniska<br>- menšie búracie práce - nakládka a odvoz stavebného odpadu&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" style={{"backgroundColor":"var(--color-blend--50)"}} name={"ponuka"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--shadow2 fs--36" content={"<span style=\"color: var(--black); text-decoration-line: underline;\">Ponuka našej techniky a prídavných zariadení</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(13, 13, 13);\">MECALAC 6MCR - Multifunkčný pásový bager</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-2);\">-podkopové lyžice - 35 cm, 60 cm, 90 cm\n<br>-nakladacia objemová lyžica ,,skid\" - 0,5m³\n<br>-paletové vidly\n<br>-rovnacia lišta na úpravu plochy\n<br>-hydraulické kliešte na guľatinu/ukladanie kameňa\n<br>-preosievačka zeminy 0,16&nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--black);\">MECALAC 10MSX - Multifunkčný kolesový bager</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-2--15);\">-podkopové lyžice - 35 cm, 60 cm, 90 cm\n<br>-svahovacia lyžica - 150 cm\n<br>-paletové vidly\n<br>-veľkoobjemová nakladacia otváracia lyžica 0,5 m³\n<br>-miešacia lyžica na betón 0,33m³\n</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(0, 0, 0);\">MECALAC TA3 - sklápač Dumper 3T/4x4/</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-1--15);\">nákladný sklápač do neprípustného terénu na prevoz sypkého materiálu, vhodný iba na stavbu</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(0, 0, 0);\">Renault Master - dodávka/sklápač do 3,5t</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1);\">nákladný automobil na prevoz materiálu, vyklápací, vhodný na prevoz paliet aj sypkého materiálu&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-pjdq33 pb--80 pt--80" name={"referencie"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/8469/238255fb4a424ab6b7a960b437646a6f_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <Title className="title-box" content={",,Garantujeme Vám spokojnosť.\""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--30 pt--80" name={"cennik"} style={{"backgroundColor":"rgba(153,157,163,1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"<span style=\"color: black\">Cenník&nbsp;služieb</span>"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--black);\">Nakoľko ku každej zákazke pristupujeme individuálne, najlepšie je naceniť služby až po osobnej obhliadke. Pre viac informácií nás kontaktujte na telefónnom čísle 0903 187 790, prípadne mailom na bagernovabana@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(153,157,163,1)"}} name={"galeria"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Galéria prác"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/2cf91efa1b30428093fedd855ad34d11_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/2cf91efa1b30428093fedd855ad34d11_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/2cf91efa1b30428093fedd855ad34d11_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/2cf91efa1b30428093fedd855ad34d11_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/58df598da1e94aa0951ec33a4c04de0f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/58df598da1e94aa0951ec33a4c04de0f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/58df598da1e94aa0951ec33a4c04de0f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/58df598da1e94aa0951ec33a4c04de0f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8469/58df598da1e94aa0951ec33a4c04de0f_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/7a6bc56fe12e4ba0a779a7d4e28581d5_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/7a6bc56fe12e4ba0a779a7d4e28581d5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/7a6bc56fe12e4ba0a779a7d4e28581d5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/7a6bc56fe12e4ba0a779a7d4e28581d5_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8469/7c92f46f264e4d27b353be910f9422b2_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8469/7c92f46f264e4d27b353be910f9422b2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8469/7c92f46f264e4d27b353be910f9422b2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8469/7c92f46f264e4d27b353be910f9422b2_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style3 mt--0" name={"kontakt-2"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Nová Baňa"} zoom={"12"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--80 pt--80" name={"fakturacneudaje"} style={{"backgroundColor":"rgba(153,157,163,1)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24" content={"<span style=\"color: var(--black);\">Fakturačné údaje:<br>Ľuboslav Moravčík<br>Rekreačná 6742/17, 968 01 Nová Baňa<br>IČO: 512 928 15<br>DIČ: 1080576222<br>IČ DPH: SK1080576222</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--20" content={"<span style=\"color: rgb(0, 0, 0); font-weight: 700;\">KONTAKTUJTE NÁS:</span>"}>
              </Text>

              <Text className="text-box fs--20" style={{"maxWidth":480}} content={"<span style=\"color: var(--black); font-weight: bold;\">mobil: +421 903 187 790 - Ľuboslav Moravčík\n<br>mail: bagernovabana@gmail.com\n<br>FB: Zemné, výkopové práce Nová Baňa a okolie\n<br>IG: bager_novabana\n</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}